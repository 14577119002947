import axios from 'axios'
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
console.log(process.env.VUE_APP_BASEURL)
let baseUrl = process.env.VUE_APP_BASEURL
// let baseUrl = `http://fraudavoid.makeapk.com:4444/api`
export default {
  async execute(method, uri, data){
    var client = axios.create({
      baseURL: baseUrl,
      timeout: (1000 * 5 * 60)
    });
    try {
      let req =await client({
        method,
        url : uri,
        withCredentials : true,
        data : data
      })
      if(req.data == 'Invalid Credentials'){
        Swal.fire({
          title: "Error!",
          text: "Untrusted Network!",
          icon: "error",
          confirmButtonText: "Ok",position: 'top-end',showConfirmButton: false,timer: 3000
        });
      }
      if(req.data.result == false && req.data.error == 'EXPIRED'){
        let planinfo_btn = document.querySelector('.plan_info_btn')
        planinfo_btn.click()
        return;
      }
      return req.data
    } catch (err) {
      console.log('Error in getting Server uri')
      console.log(err)
      if(err.response.status == 401){
        window.localStorage.setItem('user', 'null')
        Swal.fire({
          title: "Error!",
          text: 'Session Expired! Please Login Again.',
          icon: "error",
          confirmButtonText: "Ok",position: 'top-end',showConfirmButton: false,timer: 3000
        });
        window.location.href = '#/pages/login'
      }
    }
  }
}
